@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  @include fontSize(15, 20);
  color: var(--tex-def-tie2);
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding: pxToRem(10) pxToRem(16);
  background-color: var(--bac-tie1);
  box-shadow: inset 0 pxToRem(1) 0 $color-neutral-150;
  gap: pxToRem(4);
}

.footerLinks {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerLinkItem {
  margin-right: pxToRem(8);

  &:before {
    content: '•';
    margin-right: pxToRem(8);
    display: inline-block;
  }

  &:first-child:before {
    content: none;
  }

  &:last-child {
    margin-right: 0;
  }
}

.footerLink {
  color: inherit;
  text-decoration: none;
  font-weight: 800;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

@include sm {
  .root {
    padding: pxToRem(12) pxToRem(40) pxToRem(8);
    flex-direction: row;
    justify-content: space-between;
    @include fontSize(14, 18);
  }
}
