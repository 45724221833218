@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.flex-row {
  display: 'flex';
}

.flex-column {
  display: flex;
}

.countdown {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: pxToRem(16);
  width: 100%;
  justify-content: space-between;
}

.currentPurchaseImage {
  border-radius: pxToRem(8);
}

.title {
  padding: pxToRem(20) pxToRem(16);
}

.carDetailContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: pxToRem(16);
  width: 100%;
}

.container {
  padding: pxToRem(24);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: pxToRem(16);
  border-radius: pxToRem(16);
  border: 1px solid var(--whi-100);
  background: var(--color-neutral-000);
  box-shadow:
    0px 2px 4px 0px rgba(76, 76, 75, 0.06),
    0px 1px 6px 0px rgba(76, 76, 75, 0.03);
}
