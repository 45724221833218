@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  display: grid;
  grid-template:
    '😀' auto
    '💪' auto
    '👠' auto / 1fr;
  background: var(--bac-tie1);
  row-gap: pxToRem(16);
  @include fontSize(17, 24);
  padding: 0 pxToRem(12);
}

.header {
  grid-area: 😀;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: pxToRem(8);
  padding-bottom: pxToRem(16);
  border-bottom: $border-0;

  :global(.button) {
    white-space: nowrap;
  }
}

.headerTitle {
  @include fontSize(17, 24);
  margin: 0;
}

.menu {
  grid-area: 💪;
  padding: 0 0 pxToRem(16);
  margin: 0;
  border-bottom: $border-0;
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: pxToRem(24);
}

.menuItemLink {
  display: grid;
  column-gap: pxToRem(8);
  text-decoration: none;
  color: inherit;
  position: relative;
  grid-template: '🐱' auto / 1fr;
  padding: pxToRem(8) pxToRem(44) pxToRem(8) pxToRem(12);
  margin: pxToRem(-8) pxToRem(-12);
  padding-right: pxToRem(32);
  box-sizing: border-box;
  border-radius: var(--cor_rad-xs);
  overflow: hidden;

  :global(.shade) {
    background: none;

    &:before {
      display: none;
    }
  }

  .menuItemArrow {
    position: absolute;
    top: 50%;
    right: pxToRem(12);
    margin-top: pxToRem(-12);
    transform: translateX(pxToRem(-16));
    opacity: 0;
    transition:
      opacity 200ms ease-in-out,
      transform 200ms ease-in-out;
  }

  &:hover,
  &:focus-visible {
    .menuItemArrow {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &:has(.menuItemLinkImage) {
    grid-template: '🐹 🐱' auto / pxToRem(40) 1fr;
  }

  &:has(.menuItemLinkSubtitle) {
    grid-template:
      '🐱' auto
      '🐭' auto / 1fr;
  }

  &:has(.menuItemLinkImage):has(.menuItemLinkSubtitle) {
    grid-template:
      '🐹 🐱' auto
      '🐹 🐭' auto / pxToRem(40) 1fr;
  }
}

.menuItemLinkImage,
.menuItemLinkLabel,
.menuItemLinkSubtitle {
  position: relative;
}

.menuItemLinkImage {
  grid-area: 🐹;
  align-self: center;
}

.menuItemLinkLabel {
  grid-area: 🐱;
  align-self: center;
}

.menuItemLinkSubtitle {
  grid-area: 🐭;
  @include fontSize(14, 18);
  letter-spacing: pxToRem(0.2);
}

.faqs {
  grid-area: 👠;
}

.faqsTitle {
  @include fontSize(12, 16);
  letter-spacing: pxToRem(1.2);
  text-transform: uppercase;
  font-weight: 800;
  margin: 0;
}

.faqsList {
  list-style: none;
  padding: pxToRem(16) 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: pxToRem(8);
}

.faqsListItemLink {
  display: block;
  padding: pxToRem(8) 0;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

@include md {
  .root {
    grid-template:
      '😀 👠' auto
      '💪 👠' auto / 2fr 1fr;

    row-gap: 0;
    padding: 0;
    @include fontSize(15, 20);
  }

  .header {
    background: var(--for-neu-tie2);
    padding: pxToRem(24) pxToRem(32);
    border-bottom: none;
  }

  .headerTitle {
    @include fontSize(20, 28);
  }

  .menu {
    border-bottom: none;
    padding: pxToRem(32) pxToRem(32) 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 pxToRem(32);
  }

  .menuItem {
    width: 100%;
    display: inline-block;
    margin-bottom: pxToRem(32);
    height: fit-content;
  }

  .faqs {
    padding: pxToRem(32) pxToRem(32) pxToRem(32) pxToRem(20);
    border-left: $border-0;
  }
}
