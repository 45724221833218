@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/zindexes.module' as *;

$headerHeight: pxToRem(68);

.root {
  position: fixed;
  inset: $headerHeight 0 0;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.panelWrapper {
  background-color: var(--bac-tie2);
  z-index: $zIndex-modal;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.content {
  padding: pxToRem(16) pxToRem(16) pxToRem(88);
}

@include sm {
  .content {
    padding: pxToRem(24) pxToRem(40) pxToRem(56);
  }
}

@include md {
  $headerHeight: 80;
  .root {
    inset: $headerHeight 0 0;
  }
}
