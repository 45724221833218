@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  // z-index above the detail panel
  z-index: 1201;
  position: relative;
  background: var(--bac-tie2);
}

.topHeader {
  height: pxToRem(64);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topHeader,
.progressWrapper {
  padding: 0 pxToRem(16);
  max-width: pxToRem(1376);
  margin: 0 auto;
}

.carmaLogo,
.contactCarma {
  width: pxToRem(72);
  height: 100%;
  display: flex;
  align-items: center;

  .chatButton {
    padding: pxToRem(8);
    border-radius: 50%;
  }
}

.carmaLogo {
  justify-content: flex-start;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.contactCarma {
  justify-content: flex-end;
}

.mainContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  gap: pxToRem(2);
}

.isHiddenCarDetail {
  justify-content: center;
}

.carStatus {
  display: none;
}

.buttonDetails {
  display: block;
  background: none;
  border: none;
  appearance: none;
  font-family: inherit;
  cursor: pointer;
  @include fontSize(12, 16);
  letter-spacing: pxToRem(0.2);
  color: inherit;

  &.active {
    .buttonDetailsIcon {
      transform: rotateX(180deg);
    }
  }
}

.buttonDetailsIcon {
  display: block;
  width: pxToRem(18);
  height: pxToRem(6);
  margin: 0 auto pxToRem(6);
}

.isHidden {
  display: none;
}

@include md {
  .topHeader {
    height: pxToRem(76);
  }
  .carmaLogo,
  .contactCarma {
    width: pxToRem(110);
  }
  .isHiddenCarDetail {
    justify-content: flex-end;
  }
}

@include mdp {
  .mainContainer {
    padding-bottom: pxToRem(12);
  }

  .carStatus {
    display: block;
    @include fontSize(17, 24);
    font-weight: 800;
    margin: 0 0 pxToRem(4);
  }

  .buttonDetails {
    display: none;
  }

  .isHiddenCarDetail {
    justify-content: flex-end;
  }
}
