@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.fieldTitle {
  margin-bottom: pxToRem(24);
}

.form {
  :global {
    .MuiFormControl-root {
      margin-top: pxToRem(0);
      margin-bottom: pxToRem(24);
    }

    .MuiInputBase-root,
    .MuiTypography-root,
    .MuiFormLabel-root,
    .MuiToggleButtonGroup-groupedHorizontal {
      font-family: inherit;
    }
  }

  .helpInfoSection {
    margin-top: pxToRem(32);

    h4 {
      @include fontSize(20, 28);
      color: var(--tex-def-tie1);
      font-weight: 800;
      margin-bottom: pxToRem(16);
      margin-top: 0;
    }

    li {
      @include fontSize(17, 24);
      color: var(--tex-def-tie1);
      margin-left: pxToRem(8);
    }
  }

  .contentLayout {
    :global {
      .MuiFormLabel-root {
        color: rgba(21, 13, 44, 0.701961);
        font-weight: 800;
        font-size: inherit;
        line-height: inherit;
        margin-bottom: 0;
        padding-top: 0;
      }
    }
  }
}

.label {
  @include fontSize(17, 24);
  font-weight: 800;
  margin-bottom: pxToRem(8);
  display: block;
  color: var(--tex-def-tie1);
}

.error {
  :global {
    .MuiFormControlLabel-root {
      border: $border-3;
    }
  }
}

.changePaymentStep7 {
  margin-bottom: pxToRem(24);
}

.changePaymentHelperWrapper,
.changePaymentStep7 {
  padding: pxToRem(8);
  @include fontSize(15, 20);
}

.licenceHelperWrapper {
  @include fontSize(14, 18);
}

.licenseFinderButton {
  display: contents;
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: var(--font-default);
}

.egText {
  @include fontSize(15, 20);
  color: $color-uiPrimary-600;
  text-decoration: underline;
  font-weight: 800;
}

.hiddenBillingAddress {
  display: none;
}

.switchWrapper {
  margin-bottom: pxToRem(16);

  :global {
    .MuiFormGroup-root {
      background: var(--for-neu-tie2);
      border-radius: var(--cor_rad-xs);
      padding: pxToRem(12) pxToRem(16);

      &:has(.Mui-focusVisible) {
        box-shadow: 0 0 0 pxToRem(4) $color-uiPrimary-900;
      }
    }

    .MuiFormControlLabel-root {
      margin-left: 0;
      margin-right: 0;
      column-gap: pxToRem(10);
    }

    .MuiFormControlLabel-label {
      padding-top: 0;
      font-weight: 400;
      @include fontSize(17, 22);
      align-self: stretch;
    }

    .MuiSwitch-root {
      width: pxToRem(47);
      height: pxToRem(24);
      padding: 0 pxToRem(2) 0 0;
    }

    .MuiIconButton-root:hover {
      background-color: transparent;
    }

    .MuiSwitch-switchBase {
      padding: pxToRem(2) pxToRem(4) pxToRem(2) pxToRem(2);
    }

    .MuiSwitch-track {
      width: 100%;
      height: 100%;
      border-radius: var(--cor_rad-md);
      background: $color-neutral-400;
      opacity: 1;
    }

    .Mui-checked:not(.Mui-disabled) + .MuiSwitch-track {
      background: $color-uiPrimary-600;
      opacity: 1;
    }

    .MuiSwitch-thumb {
      width: pxToRem(20);
      height: pxToRem(20);
      background: var(--bac-tie1);
      box-shadow: var(--elevation-0dp);

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        width: pxToRem(1);
        height: pxToRem(13);
        background: $color-uiPrimary-600;
      }

      &:before {
        transform: translate(pxToRem(9), pxToRem(3.5)) rotate(45deg);
      }

      &:after {
        transform: translate(pxToRem(9), pxToRem(3.5)) rotate(-45deg);
      }
    }

    .Mui-checked .MuiSwitch-thumb {
      &:before {
        transform: translate(pxToRem(12), pxToRem(4)) rotate(45deg) scaleY(0.7);
      }

      &:after {
        transform: translate(pxToRem(7), pxToRem(5)) rotate(-45deg) scaleY(0.45);
      }
    }
  }

  &.error {
    :global {
      .MuiFormGroup-root {
        box-shadow: 0 0 0 pxToRem(2) $color-sentimental-error-600;
      }
    }
  }
}

//Buyer information form JoinThe1000's
.joinThe1000HeaderBuyerInfo {
  padding: pxToRem(7) pxToRem(16);
  background-color: $color-uiPrimary-050;
  border-radius: var(--cor_rad-xs);
  margin: pxToRem(8) 0 pxToRem(24) 0;
}

.footer {
  margin-top: pxToRem(24);

  .visaIssuesBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: $border-2;
    background: $color-uiPrimary-050;
    padding: pxToRem(16);

    .visaIcon {
      width: pxToRem(64);
      height: pxToRem(64);
      padding: pxToRem(4);
      margin-bottom: pxToRem(24);

      svg {
        width: pxToRem(56);
        height: pxToRem(56);
      }
    }

    .visaIssuesContent {
      margin-bottom: pxToRem(24);

      .title {
        @include fontSize(17, 24);
        font-weight: 800;
      }

      .content {
        @include fontSize(15, 20);
        font-weight: 400;
      }
    }

    .groupButton {
      display: flex;
      flex-direction: column;
      gap: pxToRem(8);
    }
  }
}

// Special styles for Trade in form
.tradeIn {
  h4 {
    margin: 0;
  }

  p {
    margin: 0;
    font-weight: 400;
    @include fontSize(14, 18);
  }

  .footer {
    margin-top: pxToRem(24);

    :global {
      .MuiButton-root {
        padding: pxToRem(11) pxToRem(20);
      }

      .MuiButton-label {
        @include fontSize(15, 18);
      }
    }
  }

  :global {
    .MuiFormControlLabel-label {
      padding: pxToRem(3) pxToRem(8) pxToRem(3) 0;
    }
  }
}

.bannerContainer {
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
  padding: pxToRem(16);
  border-radius: var(--cor_rad-xs);
  background: $color-uiPrimary-050;
}

.bannerItem {
  display: flex;
  align-items: center;
  gap: pxToRem(16);

  p {
    margin: 0;
    @include fontSize(14, 18);
    font-weight: 800;
  }
}

.iconContainer {
  width: pxToRem(40);
  height: pxToRem(40);
}

.icon {
  width: 100%;
  height: 100%;
}

.additionalInfo {
  margin-bottom: pxToRem(24);
  background-color: #ebe9ff !important;
  color: #150d2c !important;
  border-radius: pxToRem(8) !important;
  padding: pxToRem(8) !important;

  :global {
    .MuiAlert-icon {
      color: #332e69 !important;
    }
  }
}

// Special styles for Payment options form

.paymentOptions {
  container-name: payment-options;
  container-type: inline-size;

  h4 {
    margin: 0;
  }

  p {
    margin: 0;
    font-weight: 400;
    @include fontSize(14, 18);
  }

  .images {
    display: flex;
    align-items: center;
    gap: pxToRem(18);
    margin-top: pxToRem(12);
  }

  .footer {
    margin-top: pxToRem(24);

    :global {
      .MuiButton-root {
        padding: pxToRem(11) pxToRem(20);
      }

      .MuiButton-label {
        @include fontSize(15, 18);
      }
    }
  }

  :global {
    .MuiFormControl-root {
      margin-top: 0;
    }

    .MuiFormControlLabel-label {
      flex-direction: column;
      flex: 1;
      padding: pxToRem(3) pxToRem(8) pxToRem(3) 0;
      gap: pxToRem(12);
    }

    .MuiFormLabel-root {
      padding-top: 0;
    }
  }
}

// Special styles for BYOF Carma Finance form
.byofCarmaFinance {
  .carDetailsContainer {
    display: none;
  }

  .fieldContainer {
    display: flex;
    flex-direction: column;
    gap: pxToRem(8);
    margin-bottom: pxToRem(24);

    div {
      margin-bottom: 0;
    }

    :global {
      .MuiFormControl-root {
        margin-bottom: pxToRem(24);
      }
    }
  }

  .fieldContainer {
    :global {
      .MuiFormControl-root {
        margin: 0;
      }
    }
  }

  .buttonContainer {
    :global {
      .MuiButton-root {
        padding: pxToRem(11) pxToRem(20);
      }

      .MuiButton-label {
        @include fontSize(15, 18);
      }
    }
  }
}

// Special styles for Payment Summary form
.paymentSummary {
  .isDesktop {
    display: none;
  }

  .paymentSummaryFinanceQuotesNote {
    margin-top: pxToRem(24);
    @include fontSize(12, 16);
  }

  @include sm {
    .switchWrapper {
      :global {
        .MuiFormControlLabel-label {
          @include fontSize(17, 22);
        }
      }
    }

    .paymentSummaryFinanceQuotesNote {
      margin-top: pxToRem(32);
    }
  }

  .financeValue {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: pxToRem(4);
  }

  .separator {
    margin: 0;
    padding: 0;
    border: none;
    border-top: $border-0;
  }

  .carDetailsContainer {
    background: var(--bac-tie1);
    border-radius: var(--cor_rad-xs);
  }

  .purchaseSummaryButtonWrapper {
    margin-top: pxToRem(8);
  }

  .image {
    border-radius: var(--cor_rad-xs);
  }

  .carDetails {
    display: flex;
    flex-direction: column;
    gap: pxToRem(8);
    padding: pxToRem(12) pxToRem(12) pxToRem(16);

    h6,
    p {
      margin: 0;
    }

    div:first-child h6 {
      @include fontSize(20, 28);
      font-weight: 800;
    }

    div:first-child p {
      @include fontSize(15, 20);
    }
  }

  .carDetailItem {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include fontSize(14, 18);

    h6 {
      display: none;
    }

    h6:first-of-type {
      @include fontSize(15, 20);
    }

    h6:nth-child(2),
    p:nth-child(2) {
      @include fontSize(17, 24);
      font-weight: 700;
    }

    span {
      font-weight: 800;
      color: $color-uiPrimary-600;
    }
  }

  .listCardContainer {
    display: flex;
    flex-direction: column;
    gap: pxToRem(8);
    margin: pxToRem(8) 0 pxToRem(24);
  }

  .financeDetails {
    row-gap: pxToRem(4);
  }

  .financeDetailsImage {
    grid-column: 2 / 3;
    justify-self: end;
  }

  .billingAddressContainer {
    margin: pxToRem(24) 0;
    h6 {
      margin: 0 0 pxToRem(16);
      font-weight: 800;
      @include fontSize(20, 28);
    }
  }

  .radioContainer {
    :global {
      h4 {
        margin: 0;
        @include fontSize(20, 28);
        font-weight: 800;
      }

      p {
        @include fontSize(17, 24);
        font-weight: 400;
        color: var(--tex-def-tie2);
      }

      .MuiFormHelperText-root {
        color: var(--tex-err-tie1);
      }

      .MuiFormControlLabel-label {
        flex-direction: column;
        padding: 0;
      }

      .MuiFormGroup-root {
        row-gap: pxToRem(16);
      }

      .MuiFormControl-root {
        margin: pxToRem(16) 0 pxToRem(32);
      }
    }
  }

  .reservationPaymentContainer {
    margin: pxToRem(16) 0 pxToRem(24);

    h6 {
      margin: 0;
      font-weight: 800;
      @include fontSize(20, 28);
    }

    p {
      margin: 0;
    }
  }

  .depositAmount {
    display: flex;
    justify-content: space-between;
    margin: pxToRem(24) 0 pxToRem(8);
    @include fontSize(17, 24);
  }

  .fees {
    display: flex;
    justify-content: space-between;
    margin: pxToRem(24) 0 pxToRem(12);
    align-content: center;
    @include fontSize(17, 24);

    .feesLabel {
      display: flex;
      justify-content: space-between;
    }
  }

  .reservationTotal {
    display: flex;
    justify-content: space-between;
    margin: pxToRem(8) 0 pxToRem(24);
    @include fontSize(17, 24);
    font-weight: 800;
  }

  .buttonContainer {
    :global {
      .MuiButton-root {
        padding: pxToRem(11) auto;
      }

      .MuiButton-label {
        @include fontSize(15, 18);
      }
    }
  }

  .dueHeader {
    margin: pxToRem(24) 0 0;
    @include fontSize(20, 28);
    font-weight: 800;
    color: var(--tex-def-tie1);
  }

  .paymentSummaryButton {
    display: flex;
    gap: pxToRem(8);
  }

  .editButton {
    background: var(--bac-tie1);
    border: $border-2;
    box-shadow: none;
    padding: pxToRem(8) pxToRem(12);
    @include fontSize(15, 18);
  }

  .instantFinanceRepaymentLabel {
    :global {
      p {
        font-weight: 400 !important;
      }
    }
  }

  .whatHappensNext {
    padding: pxToRem(16) pxToRem(24);
    border-radius: var(--cor_rad-lg);
    border: $border-0;
  }

  .infoButton:global(.button) {
    margin: pxToRem(-8) 0;
    display: inline-flex;
    vertical-align: middle;
  }
}

.financeInfoButtonContainer {
  display: flex;
  justify-content: center;
  margin: pxToRem(16) 0 pxToRem(24);
}

.financeInfoButton {
  width: max-content;
}

.financeInfoSubmitButton {
  display: flex;
  justify-content: flex-end;
}

.noteFinanceInfo {
  @include fontSize(14, 18);
}

// special styles for edit information card
.cardContainer {
  padding: pxToRem(12);
  background: var(--bac-tie1);
  border-radius: var(--cor_rad-xs);
  box-shadow: var(--elevation-0dp);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h6 {
    margin: 0;
    @include fontSize(20, 28);
    font-weight: 800;
  }

  :global {
    .MuiButton-root {
      padding: pxToRem(11) pxToRem(12);
    }

    .MuiButton-label {
      @include fontSize(15, 18);
      font-weight: 600;
    }
  }
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: pxToRem(16);
  margin-top: pxToRem(16);
}

.listItem {
  display: grid;
  grid-template-columns: 37% auto;
  justify-content: space-between;
  column-gap: pxToRem(4);

  p {
    margin: 0;
    @include fontSize(17, 24);
  }

  p:first-child {
    font-weight: 800;
  }
}

.value {
  align-self: center;
  padding-left: pxToRem(20);
  text-align: end;
}

// Special styles for Finance Quote Info form
.financeQuoteInfo {
  .fieldHeader {
    margin: pxToRem(16) 0 pxToRem(8);
    @include fontSize(20, 28);
    font-weight: 800;
  }

  .fieldTitle {
    margin: pxToRem(8) 0;
    @include fontSize(17, 24);
    font-weight: 800;
  }

  .loanCalculatorFormContainer {
    padding: pxToRem(24) pxToRem(16);
    background-color: var(--bac-tie1);
    box-shadow:
      inset 0 0 0 1px $color-neutral-100,
      var(--elevation-1dp);
    border-radius: var(--cor_rad-xs);
  }

  .loanCalculatorTitle {
    margin: 0;
    @include fontSize(17, 24);
    font-weight: 800;
  }

  .loanCalculatorSubTitle {
    margin: 0 0 pxToRem(8);
    @include fontSize(15, 20);
    color: var(--tex-def-tie2);
  }

  .carPurchasePrice {
    :global {
      .MuiFormControl-root {
        margin-bottom: pxToRem(24);
      }
    }
  }

  .upfrontContribution {
    :global {
      .MuiFormControl-root {
        margin: 0 0 pxToRem(4);
      }
    }
  }

  .loanCalculatorDescription {
    margin: pxToRem(4) 0 pxToRem(24);
    @include fontSize(14, 18);
    letter-spacing: pxToRem(0.2);
    color: var(--tex-def-tie3);
  }

  .totalFinanceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include fontSize(17, 24);
    font-weight: 800;
    color: var(--tex-def-tie1);
  }

  .totalFinanceTitle {
    margin: 0;
    width: 40%;
  }

  .totalFinanceAmount {
    margin: 0;
  }

  .businessNotification {
    margin: pxToRem(24) 0;
    padding: pxToRem(8);

    svg {
      padding-top: 0;
    }

    p {
      padding: 0;
      @include fontSize(17, 24);
    }
  }

  .maritalStatus {
    margin: pxToRem(24) 0 0;

    :global {
      .MuiFormControl-root {
        margin-bottom: pxToRem(24);
      }
    }
  }

  .residentialStatusContainer {
    display: flex;
    flex-direction: column;
    gap: pxToRem(16);
    margin: pxToRem(16) 0 0;

    :global {
      .MuiFormControl-root {
        margin: 0;
      }
    }
  }

  .radioHeader {
    margin-top: pxToRem(16);
  }

  .radioContainer {
    :global {
      .MuiFormGroup-root {
        flex-direction: row;
        justify-content: space-between;
        gap: pxToRem(8);
      }

      .MuiFormControlLabel-root {
        flex: 1;
        margin: 0;
        padding: pxToRem(10);
        background: var(--bac-tie1);
        border: 1px solid $color-neutral-000;
        border-radius: var(--cor_rad-md);
        box-shadow: var(--elevation-1dp);
      }

      .MuiFormControl-root {
        margin-bottom: pxToRem(16);
      }
    }
  }

  .employmentContainer {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: pxToRem(16);
    margin: pxToRem(8) 0 pxToRem(24);

    :global {
      .MuiFormControl-root {
        margin: 0;
      }
    }
  }

  .visaInformationDescription {
    @include fontSize(14, 18);
    color: var(--tex-def-tie3);

    p {
      margin: 0;
    }

    a {
      font-weight: 800;
      color: var(--tex-def-tie1);
    }
  }

  .visaSubclassNumber {
    :global {
      .MuiFormControl-root {
        margin-bottom: pxToRem(4);
      }
    }
  }

  .visaExpiryDate {
    :global {
      .MuiFormControl-root {
        margin: pxToRem(8) 0 0;
      }
    }
  }

  .termConditionsContainer {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    margin: pxToRem(8) 0 pxToRem(8);

    .titleContent {
      @include fontSize(17, 24);
      font-weight: 800;
    }

    .content {
      flex: 1;
      overflow-y: scroll;
      @include fontSize(15, 18);
      background: $color-neutral-000;
      p {
        padding-left: pxToRem(32);
      }

      p strong {
        @include fontSize(17, 24);
      }

      a,
      u {
        font-weight: 800;
        color: var(--tex-def-tie1);
      }

      ul {
        margin: pxToRem(16) 0;
        padding-left: pxToRem(32);
      }

      ul:nth-child(3) {
        padding-left: pxToRem(56);
      }

      p:nth-child(5) {
        padding-bottom: pxToRem(16);
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: pxToRem(24);
  }

  .notification {
    p {
      margin: pxToRem(16) 0 0;
      @include fontSize(15, 20);
      white-space: nowrap;
      color: var(--tex-def-tie1);
    }

    br {
      display: none;
    }
  }
}

.financeQuoteLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: pxToRem(26);
  margin-bottom: pxToRem(26);
  gap: pxToRem(20);
  font-weight: 800;
  font-size: pxToRem(15);
}

//For step 7 finance quotes form
.financeQuotes {
  h4 {
    margin: 0;
  }

  .checkoutRadio {
    margin-bottom: pxToRem(8);
  }

  :global {
    .MuiFormLabel-root {
      width: 100%;
      margin-bottom: 0;
      padding-top: 0;
    }

    .MuiFormControlLabel-label {
      padding: 0;
      width: 100%;
    }

    .MuiFormGroup-root .MuiFormControlLabel-root {
      // Needed to override highly specifc base styling
      padding: pxToRem(11) pxToRem(16) !important;
      box-shadow: none;
      border: $border-2;

      // style for disabled options
      &.Mui-disabled {
        &:has(.is-loading) {
          background-color: var(--bac-tie1);
          box-shadow: none;
          border: $border-2;

          &:hover {
            background-color: var(--bac-tie1);
            box-shadow: none;
          }
        }
        &:has(.is-unavailable) {
          background-color: transparent;
          box-shadow: none;

          &:hover {
            background-color: transparent;
            box-shadow: none;
          }
        }

        background-color: $color-neutral-100;
        box-shadow: var(--elevation-0dp);

        padding: pxToRem(11) pxToRem(16);

        &:hover {
          background-color: $color-neutral-100;
          box-shadow: var(--elevation-0dp);
        }
      }
    }

    .MuiIconButton-root {
      padding: pxToRem(2) 0 0 0;
    }
  }

  .isDesktop {
    display: none;
  }

  .displayNone {
    display: none;
  }

  .layout {
    width: 100%;
  }

  .financeOptionLabel {
    display: grid;
    row-gap: pxToRem(54);
    margin-bottom: pxToRem(24);
  }

  .optionTopLayout {
    display: flex;
    justify-content: space-between;
    min-width: fit-content;
  }

  .dynamicFee {
    p {
      @include fontSize(14, 18);
      font-weight: 400;
      color: var(--tex-def-tie1);
    }

    strong {
      @include fontSize(17, 24);
      font-weight: 800;
      color: var(--tex-def-tie1);
    }

    b {
      @include fontSize(20, 28);
      font-weight: 800;
      color: var(--tex-def-tie1);
    }
  }

  .divider {
    margin: pxToRem(10) pxToRem(-8) pxToRem(10) pxToRem(-51);
    border: $border-0;
    width: calc(100% + pxToRem(57));
  }

  .fixedFee {
    display: flex;
    justify-content: space-between;
  }

  .fixedFeeTitle {
    @include fontSize(14, 18);
    font-weight: 400;
    color: var(--tex-def-tie1);
  }

  .feeLayout {
    text-align: right;
  }

  .fixedFeeValue {
    @include fontSize(14, 18);
    font-weight: 800;
    color: var(--tex-def-tie1);
  }

  .breakdownButton {
    @include fontSize(14, 18);
    font-weight: 800;
    color: var(--tex-def-tie1);
    text-decoration: underline;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    text-align: right;
  }

  .carmaProvider {
    p {
      @include fontSize(15, 20);
      font-weight: 400;
      color: var(--tex-def-tie2);
    }

    strong {
      @include fontSize(17, 24);
      font-weight: 800;
      color: var(--tex-def-tie1);
    }
  }

  .disabledOption {
    width: 100%;
  }

  .disabledLayout {
    display: grid;
    grid-template-areas:
      'a b b'
      'c c c';
    grid-template-columns: auto auto auto;
  }

  .unavailableLabel {
    @include fontSize(17, 24);
    color: var(--tex-def-tie1);
    grid-area: a;
  }

  .lenderImageContainer {
    grid-area: b;
    text-align: right;
  }

  .disabledText {
    grid-area: c;

    h4 {
      @include fontSize(17, 24);
      font-weight: 800;
      color: var(--tex-def-tie1);
    }

    p,
    li {
      @include fontSize(14, 18);
      font-weight: 400;
      color: var(--tex-def-tie2);
    }
  }
}

//For step 9 checkout confirmation page
.confirmationPage {
  min-height: 100vh;
  background-color: var(--bac-tie2);

  .root {
    padding: 0 pxToRem(16);

    display: grid;
    row-gap: pxToRem(24);

    .imageContainer {
      margin: auto;

      img {
        display: block;
        width: pxToRem(162) !important;
        height: pxToRem(70) !important;
        object-fit: contain;
        object-position: center;
        margin: 0 pxToRem(-32);
      }
    }

    .carDetailsContainer {
      background: var(--bac-tie1);
      border-radius: var(--cor_rad-xs);
      overflow: hidden;
      margin-bottom: pxToRem(8);

      .image {
        width: 100%;
        height: auto;
      }
    }

    .carDetails {
      display: flex;
      flex-direction: column;
      gap: pxToRem(8);
      padding: pxToRem(12) pxToRem(12) pxToRem(16);
      background-color: var(--bac-tie1);

      h6,
      p {
        margin: 0;
      }

      div:first-child h6 {
        @include fontSize(20, 28);
        font-weight: 800;
      }

      div:first-child p {
        @include fontSize(15, 20);
      }
    }

    .contentWrapper {
      display: grid;
      row-gap: pxToRem(24);
    }

    .title {
      p {
        margin: 0;
      }

      padding: 0 pxToRem(16);
      @include fontSize(26, 32);
      font-weight: 800;
      color: var(--tex-def-tie1);
      text-align: center;
    }

    .deliveryDocumentLayout {
      display: grid;
      row-gap: pxToRem(24);
    }

    .description {
      @include fontSize(17, 24);
      font-weight: 400;
      color: var(--tex-def-tie1);
      text-align: center;
    }

    .blockDescription {
      @include fontSize(17, 24);
      font-weight: 400;
      color: var(--tex-def-tie1);
      text-align: center;
    }

    .blockContainer {
      display: flex;
      flex-direction: column;
      gap: pxToRem(16);
    }

    .tradeInBlock {
      display: grid;
      row-gap: pxToRem(8);
      padding: pxToRem(24);
      background-color: var(--bac-tie2);
      border-radius: var(--cor_rad-xs);
      text-align: center;

      h6 {
        margin: 0;
        @include fontSize(17, 24);
        font-weight: 800;
        color: var(--tex-def-tie1);
      }

      p {
        margin: 0;
        @include fontSize(17, 24);
        font-weight: 400;
        color: var(--tex-def-tie1);
      }
    }

    .deliveryDocumentBlock {
      display: grid;
      row-gap: pxToRem(8);
      padding: pxToRem(24);
      background-color: var(--bac-tie2);
      border-radius: var(--cor_rad-xs);
      text-align: left;

      li {
        @include fontSize(17, 24);
        font-weight: 400;
        color: var(--tex-def-tie1);
      }
    }

    .block,
    .dynamicStyledBlock {
      padding: pxToRem(24);
      background-color: var(--bac-tie2);
      border-radius: var(--cor_rad-xs);
      display: grid;
      row-gap: pxToRem(16);
      text-align: center;

      h6 {
        margin: 0;
        @include fontSize(17, 24);
        font-weight: 800;
        color: var(--tex-def-tie1);
      }

      p,
      div {
        margin: 0;
        @include fontSize(17, 24);
        font-weight: 400;
        color: var(--tex-def-tie1);
      }

      .icon {
        width: pxToRem(80);
        height: pxToRem(80);
        margin: auto;
      }
    }

    .mapImage {
      height: pxToRem(80);
    }

    .divider {
      width: 100%;
      margin: auto;
      border: $border-0;
    }

    .whatsNextBlock,
    .ownFinanceNextStep {
      display: grid;
      row-gap: pxToRem(21);

      h5 {
        margin: 0;
        @include fontSize(26, 32);
        font-weight: 800;
        color: var(--tex-def-tie1);
        text-align: center;
      }

      ul {
        counter-reset: item;
        list-style: none;
        padding: 0;
        display: grid;
        row-gap: pxToRem(21);
        margin: 0;
      }

      li {
        @include fontSize(17, 24);
        color: var(--tex-def-tie1);
        display: flex;
        align-items: center;
        gap: pxToRem(15);

        &::before {
          counter-increment: item;
          content: counter(item);
          padding: pxToRem(9) pxToRem(16);
          border-radius: 50%;
          background-color: $color-neutral-200;
          font-weight: 800;
        }
      }
    }

    .carmaDetails {
      h6 {
        margin: 0;
        @include fontSize(20, 28);
        font-weight: 800;
      }

      @include fontSize(17, 24);
      color: var(--tex-def-tie1);
      padding: pxToRem(16) 0 pxToRem(16) pxToRem(24);
      background: var(--for-neu-tie2);
      box-shadow: var(--elevation-0dp);
      border-radius: var(--cor_rad-lg);
    }

    .deliveryDocumentBlock {
      padding: 0 pxToRem(20);
    }

    .buttonContainer {
      width: pxToRem(250);
      height: pxToRem(72);
      margin: auto;
    }
  }
}

.disclaimer {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  background: $color-neutral-100;
  margin: pxToRem(8) 0;
  border-radius: pxToRem(8);
}

.helperModal {
  :global {
    .modalContentMain {
      @include fontSize(15, 20);
      font-weight: 400;
      padding: pxToRem(8) pxToRem(12);
    }

    .modalFooter {
      button {
        border-radius: pxToRem(16);
        background: $color-sentimental-info-700;
        min-height: pxToRem(40);
        padding: pxToRem(8);
        color: $color-neutral-000;
      }
    }
  }
}

.bottomContent {
  display: flex;
  flex-direction: column;
  gap: pxToRem(24);

  &.submitAboveFooter {
    flex-direction: column-reverse;
  }

  .financeQuoteModalFooter {
    display: flex;
    justify-content: flex-end;
  }
}

.blockUSPContainer {
  display: grid;
  grid-auto-flow: row;
  gap: pxToRem(16);
  margin-bottom: pxToRem(24);

  .uspIcon,
  .uspIcon svg {
    width: pxToRem(40);
    height: pxToRem(40);
  }

  .uspContent {
    margin-top: pxToRem(8);
    @include fontSize(17, 24);
    font-weight: 400;
    color: var(--tex-def-tie1);
  }
}

.removeTopMargin {
  margin-top: 0;
}

.note {
  p {
    @include fontSize(14, 18);
    letter-spacing: pxToRem(0.2);
    color: var(--tex-def-tie1);
  }
}

.socialProof {
  margin: pxToRem(24) 0;
}

.joinThe1000Header {
  padding: pxToRem(7) pxToRem(16);
  margin: pxToRem(8) 0 pxToRem(24) 0;
}

.businessNotification {
  display: flex;
  gap: pxToRem(12);
  margin: 0 0 pxToRem(24) 0;
  padding: pxToRem(8);
  background: $color-uiPrimary-100;
  border-radius: var(--cor_rad-xs);
  color: $color-uiPrimary-600;

  svg {
    padding-top: pxToRem(7);
  }

  p {
    margin: 0;
    padding: pxToRem(8) 0;
    @include fontSize(15, 20);
  }
}

@include sm {
  // Buyer Form
  .inputGroupRow {
    display: flex;
    gap: pxToRem(8);
  }

  .bannerContainer {
    flex-direction: row;
    padding: pxToRem(16) pxToRem(32);
  }

  .bannerItem {
    flex: 1;
  }

  .tradeIn {
    .form {
      :global {
        .MuiFormControl-root {
          margin-top: pxToRem(16);
        }
      }
    }

    .footer {
      margin-top: pxToRem(32);

      :global {
        .MuiButton-root {
          padding: pxToRem(16) pxToRem(20);
        }

        .MuiButton-label {
          @include fontSize(17, 24);
        }
      }
    }
  }

  .paymentOptions {
    :global {
      .MuiFormControl-root {
        margin-bottom: pxToRem(8);
      }
    }

    .radioContainer {
      :global {
        .MuiTypography-root {
          display: flex;
          gap: pxToRem(8);
        }
      }
    }

    .radioContent {
      flex: 1;

      p {
        max-width: pxToRem(336);
      }
    }

    :global {
      .MuiFormControlLabel-label {
        flex-direction: row;
      }
    }

    .footer {
      margin-top: pxToRem(32);

      :global {
        .MuiButton-root {
          padding: pxToRem(16) pxToRem(20);
        }

        .MuiButton-label {
          @include fontSize(17, 24);
        }
      }
    }
  }

  .byofCarmaFinance {
    flex-direction: column;

    .fieldContainer {
      margin-bottom: pxToRem(32);
    }

    .buttonContainer {
      :global {
        .MuiButton-root {
          padding: pxToRem(16) pxToRem(20);
        }

        .MuiButton-label {
          @include fontSize(17, 24);
        }
      }
    }
  }

  .paymentSummary {
    .carDetails {
      flex-direction: row;
      justify-content: space-between;
      padding: pxToRem(16);
      align-items: center;

      div:first-child h6 {
        @include fontSize(26, 32);
      }

      div:first-child p {
        @include fontSize(17, 24);
      }
    }

    .financeValue {
      justify-content: space-between;
      flex-direction: row;
    }

    .editButton {
      @include fontSize(17, 24);
      padding: pxToRem(16) pxToRem(20);
    }

    .carDetailItem {
      gap: pxToRem(24);

      h6:first-of-type,
      p:first-of-type {
        @include fontSize(20, 28);
        font-weight: 800;
      }
    }

    .headerContainer {
      :global {
        .MuiButton-root {
          padding: pxToRem(16) pxToRem(20);
        }

        .MuiButton-label {
          @include fontSize(17, 24);
        }
      }
    }

    .listCardContainer {
      gap: pxToRem(16);
    }

    .listContainer {
      gap: pxToRem(16);
    }

    .listItem {
      grid-template-columns: 1fr 2fr;
    }

    .value {
      padding: 0;
      text-align: start;
      word-break: break-word;
    }

    .financeDetails {
      grid-template-columns: 1.25fr 2fr 0.5fr;
    }

    .financeDetailsImage {
      grid-column: 3 / 4;
    }

    .smallGap {
      gap: pxToRem(8);
    }

    .mediumGap {
      gap: pxToRem(10);
    }

    .billingAddressContainer {
      margin: pxToRem(36) 0;
    }

    .reservationPaymentContainer {
      margin: pxToRem(16) 0 pxToRem(24);
    }

    .depositAmount {
      margin: pxToRem(16) 0 pxToRem(12);
    }

    .reservationTotal {
      margin: pxToRem(8) 0 pxToRem(24);
    }

    .buttonContainer {
      :global {
        .MuiButton-root {
          padding: pxToRem(16) auto;
        }

        .MuiButton-label {
          @include fontSize(17, 24);
        }
      }
    }

    .socialProof {
      margin: pxToRem(24) 0;
    }
  }

  .financeQuoteInfo {
    .businessNotification {
      margin: pxToRem(32) 0;
    }

    .maritalStatus {
      margin: pxToRem(32) 0 0;

      :global {
        .MuiFormControl-root {
          margin-bottom: pxToRem(32);
        }
      }
    }

    .residentialStatusContainer {
      flex-direction: row;
      gap: pxToRem(8);
      margin: pxToRem(16) 0 0;
    }

    .employmentContainer {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: pxToRem(16) pxToRem(8);
      margin: pxToRem(8) 0 pxToRem(32);
    }

    .termConditionsContainer {
      margin-top: pxToRem(32);
    }

    .notification {
      p {
        @include fontSize(17, 24);
      }
    }
  }

  //For step 7 finance quotes form
  .financeQuotes {
    .isMobile {
      display: none;
    }

    .isDesktop {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .layout {
      display: flex;
    }

    .financeOptionLabel {
      display: flex;
      gap: pxToRem(24);
    }

    .dynamicFee {
      p {
        @include fontSize(17, 24);
      }

      strong {
        @include fontSize(20, 28);
      }

      b {
        @include fontSize(26, 32);
      }
    }

    .divider {
      width: pxToRem(1);
      margin: pxToRem(-16) pxToRem(28) pxToRem(-10) pxToRem(30);
    }

    .optionBottomLayout {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .fixedFee {
      flex-direction: row-reverse;
      gap: pxToRem(10);
    }

    .fixedFeeTitle {
      @include fontSize(15, 20);
    }

    .feeLayout {
      text-align: start;
    }

    .fixedFeeValue {
      @include fontSize(15, 20);
    }

    .disabledLayout {
      grid-template-columns: pxToRem(191) auto pxToRem(84);
      grid-template-areas: 'a c b';
    }

    .imageContainer {
      text-align: end;
    }

    :global {
      .MuiFormGroup-root {
        .MuiFormControlLabel-root {
          padding: pxToRem(11) pxToRem(16);
        }
      }
    }

    .visaIssuesBlock {
      display: grid;
      grid-template-columns: fit-content(56px) auto;
      column-gap: pxToRem(24);

      .groupButton {
        grid-column: span 2;
      }
    }
  }

  //For step 9 checkout confirmation page
  .confirmationPage {
    background-color: var(--bac-tie2);

    .root {
      max-width: pxToRem(848);
      margin: auto;

      .carDetailsContainer {
        width: pxToRem(490);
        margin: auto;
      }

      .title {
        @include fontSize(32, 40);
      }

      .blockDescription {
        @include fontSize(20, 28);
      }

      .dynamicStyledBlock {
        display: grid;
        column-gap: pxToRem(16);
        grid-template-columns: pxToRem(80) auto;
        text-align: left;

        .tradeinIcon {
          margin: 0;
        }
      }

      .blockContainer {
        display: flex;
        flex-direction: row;
        gap: pxToRem(24);

        div {
          flex: 1;
        }
      }

      .whatsNextBlock,
      .ownFinanceNextStep {
        width: pxToRem(630);
        margin: auto;
        padding: 0 pxToRem(24);
      }
    }
  }

  .socialProof {
    margin: pxToRem(32) 0;
  }

  .joinThe1000Header {
    margin: pxToRem(8) 0 pxToRem(32) 0;
  }

  .joinThe1000HeaderBuyerInfo {
    margin: pxToRem(8) 0 pxToRem(32) 0;
  }

  .blockUSPContainer {
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    gap: pxToRem(24);

    .uspIcon,
    .uspIcon svg {
      width: pxToRem(32);
      height: pxToRem(32);
    }
  }

  .helperModal {
    :global {
      .modalContentMain {
        padding: pxToRem(8) pxToRem(16);
      }

      .modalFooter {
        button {
          padding: pxToRem(22) pxToRem(14);
        }
      }
    }
  }
}

@include md {
  .checkoutDescription {
    section {
      padding-bottom: pxToRem(24);
    }
  }

  .paymentOptions {
    .radioContent {
      p {
        max-width: pxToRem(384);
      }
    }

    .footer {
      margin-top: pxToRem(24);
    }
  }

  .paymentSummary {
    .isMobile {
      display: none;
    }

    .isDesktop {
      display: block;
    }

    .carDetailsContainer {
      flex: 0 0 40%;
      align-self: flex-start;
    }

    .carDetails {
      flex-direction: column;
      padding: pxToRem(16) pxToRem(26) pxToRem(24);
      gap: pxToRem(16);
    }

    .priceContainer {
      width: 100%;
    }

    .listItem {
      grid-template-columns: 1fr 2.5fr;
    }

    .financeDetails {
      grid-template-columns: 1.9fr 2fr 0.5fr;
    }

    .billingAddressContainer {
      margin: pxToRem(28) 0;
    }

    .buttonContainer {
      width: 50%;
    }
  }

  .byofCarmaFinance {
    display: flex;
    gap: pxToRem(48);

    .carDetailsContainer {
      display: block;
      flex: 0 0 40%;
      align-self: flex-start;
      background: var(--bac-tie1);
      border-radius: var(--cor_rad-xs);
    }

    .image {
      border-radius: var(--cor_rad-xs);
    }

    .carDetails {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      align-self: flex-start;
      gap: pxToRem(16);
      padding: pxToRem(16) pxToRem(26) pxToRem(24);

      h5,
      h6 {
        margin: 0;
      }

      p {
        display: inline-block;
        margin: 0;
        @include fontSize(17, 24);
      }

      span {
        margin: pxToRem(0) pxToRem(8);
        @include fontSize(17, 24);
      }
    }

    .carModel {
      @include fontSize(26, 32);
      font-weight: 800;
    }

    .priceContainer {
      width: 100%;
    }

    .purchasePriceLabel {
      @include fontSize(20, 28);
      font-weight: 800;
    }

    .carDetailItem {
      display: flex;
      justify-content: space-between;
      gap: pxToRem(24);
      @include fontSize(14, 18);

      a {
        font-weight: 800;
        color: $color-uiPrimary-600;
      }
    }

    .price {
      @include fontSize(20, 28);
      font-weight: 800;
    }

    .fieldContainer {
      margin-bottom: pxToRem(24);
    }

    .buttonContainer {
      width: 50%;
    }
  }

  .financeQuoteInfo {
    .businessNotification {
      margin: pxToRem(24) 0;
    }

    .maritalStatus {
      margin: pxToRem(24) 0 0;

      :global {
        .MuiFormControl-root {
          margin-bottom: pxToRem(24);
        }
      }
    }

    .residentialStatusContainer {
      margin: pxToRem(16) 0 0;
    }

    .employmentContainer {
      margin: pxToRem(8) 0 pxToRem(24);
    }

    .termConditionsContainer {
      margin-top: pxToRem(24);
    }

    .footer {
      flex-direction: row;
      gap: pxToRem(16);
    }

    .notification {
      p {
        margin: 0;
      }

      br {
        display: block;
      }
    }
  }

  .financeQuotesButton {
    max-width: pxToRem(320);
  }

  .financeQuotesModalButton {
    width: pxToRem(78);
  }

  .helperModal {
    min-width: pxToRem(736);
    min-height: pxToRem(576);
  }
}

@include mdp {
  .footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: pxToRem(8);

    &:has(.visaIssuesBlock) {
      display: flex;
    }
  }

  .socialProof {
    //Added important to override display:grid in SocialProof component
    display: none !important;
  }

  .joinThe1000Header {
    display: none !important; //To override component's root styling
  }

  .joinThe1000HeaderBuyerInfo {
    display: none !important; //To override component's root styling
  }
}

//For Checkout step3
.deliveryOptions {
  .displayNone {
    display: none;
  }

  .form {
    :global {
      legend.MuiFormLabel-root {
        display: none;
      }
    }
  }

  .form:has(.errors) {
    :global {
      .MuiFormControlLabel-root {
        border: $border-3;
      }
    }
  }

  .loadingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .greenText {
    padding: pxToRem(12) pxToRem(12);
    background-color: $color-sentimental-success-050;
    border-radius: var(--cor_rad-xs);
    box-shadow: var(--elevation-0dp);
    @include fontSize(15, 20);
    color: var(--gre-600);

    p {
      color: var(--gre-600) !important;
    }
  }

  .redText {
    background-color: rgba(255, 232, 235, 1);
    box-shadow: var(--elevation-0dp);
    border-radius: var(--cor_rad-xs);
    padding: pxToRem(8) pxToRem(12);
    @include fontSize(15, 20);
    color: $color-sentimental-error-700;

    p {
      color: $color-sentimental-error-700 !important;
    }
  }

  .flexLabel {
    margin-top: pxToRem(15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: pxToRem(16);
  }

  .contentLayout {
    display: grid;

    :global {
      .MuiFormGroup-root {
        margin-top: pxToRem(20);
        row-gap: 0;
      }

      .MuiFormControl-root.MuiFormControl-fullWidth {
        margin-bottom: pxToRem(8);
      }

      .FieldWrapper_root {
        margin-bottom: 0px;
      }

      .CheckoutStep3Form_addressNoteInput {
        margin-top: pxToRem(20);
      }
    }
  }

  .divider {
    border: $border-0;
    width: calc(100% + pxToRem(26));
    margin: pxToRem(15) 0 0 - pxToRem(14);
  }

  .carmaCollectLabel {
    font-weight: 800;
    font-size: pxToRem(17);
    line-height: pxToRem(24);
    color: var(--tex-def-tie1);
  }

  .deliveryOptionAddressNoteInput {
    margin-top: pxToRem(20);
    display: grid;
    row-gap: pxToRem(8);

    :global {
      .MuiFormControl-root {
        .MuiInputBase-root {
          padding-bottom: pxToRem(40);
        }
      }
    }
  }

  .carmaCollectAddressLabel {
    font-weight: 400;
    font-size: pxToRem(15);
    line-height: pxToRem(20);
    color: var(--tex-def-tie1);
  }

  .iconContainer {
    width: pxToRem(52);
    height: pxToRem(52);
  }

  .icon {
    width: 100%;
    height: 100%;
  }
}

@container payment-options (min-width: #{pxToRem(704)}) {
  .paymentOptions {
    .images {
      align-self: end;
      max-width: max-content;
      position: absolute;
      right: pxToRem(16);
      bottom: pxToRem(11);
      margin-top: 0;
    }
  }
}
