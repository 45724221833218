@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  background: var(--bac-tie2);
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template:
    '😀' pxToRem(68)
    '👕' auto / 1fr;
}

.header {
  grid-area: 😀;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $zIndex-headerAbove;
}

.container {
  grid-area: 👕;
  padding: pxToRem(24) pxToRem(16);
  max-width: pxToRem(1376);
  margin: auto;
  width: 100%;
  min-height: 100%;
  overflow: auto;
}

.content {
  overflow: hidden;
  // Padding and negative margin hack to still allow visually offsetted elements to be displayed
  padding: pxToRem(16);
  margin: pxToRem(-16);
  min-height: calc(100vh - #{pxToRem(68 + 24 * 2)}); // Header height plus vertical padding
}

.productTile,
.footer {
  display: none;
}

@include md {
  .root {
    grid-template:
      '😀' pxToRem(80)
      '👕' auto / 1fr;
  }

  .container {
    padding-left: pxToRem(40);
    padding-right: pxToRem(40);
  }
}

@include mdp {
  .root {
    grid-template:
      '😀' pxToRem(80)
      '👕' auto
      '👖' pxToRem(40) / 1fr;
  }

  .container {
    display: grid;
    grid-template: '🚗 🛣' 1fr / 2fr 3fr;
    column-gap: pxToRem(32);
  }

  .productTile {
    grid-area: 🚗;
    display: block;
  }

  .content {
    grid-area: 🛣;
    min-height: calc(100vh - #{pxToRem(80 + 40 + 24 * 2)}); // Header height, footer height, vertical padding
  }

  .footer {
    grid-area: 👖;
    display: block;
  }
}

@include lg {
  .container {
    column-gap: pxToRem(48);
  }
}
